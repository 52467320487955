import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

import './ClientsSlider.css'

import sd1 from "../../assets/images/sd1.jpg";


const ClientsSlider = () => {
  return (
    <>
      <div className="clint-case">
        
        <Swiper
            slidesPerView={3}
            spaceBetween={50}
            loop={true}
            pagination={{
            clickable: true,         
            }}
            breakpoints={{
                250: {
                    slidesPerView: 1,
                },
                450: {
                    slidesPerView: 1,
                },
                640: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper tm-swiper container"
        >
        
        <div className="supes">
                    
            <SwiperSlide className="supes-slide">
                <div className="clint-swipe">
                    <div className="cls-1">
                        <img src={sd1} alt="" />
                    </div>
                    
                    <div className="cls-2">
                        Gorem ipsum
                    </div>
                    
                    <div className="cls-3">
                        Gorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </div>
                </div>
            </SwiperSlide>    
                                                                                
            <SwiperSlide className="supes-slide">
                <div className="clint-swipe">
                    <div className="cls-1">
                        <img src={sd1} alt="" />
                    </div>
                    
                    <div className="cls-2">
                        Gorem ipsum
                    </div>
                    
                    <div className="cls-3">
                        Gorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </div>
                </div>
            </SwiperSlide>    
                                                                                
            <SwiperSlide className="supes-slide">
                <div className="clint-swipe">
                    <div className="cls-1">
                        <img src={sd1} alt="" />
                    </div>
                    
                    <div className="cls-2">
                        Gorem ipsum
                    </div>
                    
                    <div className="cls-3">
                        Gorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </div>
                </div>
            </SwiperSlide>    
                                                                                
            <SwiperSlide className="supes-slide">
                <div className="clint-swipe">
                    <div className="cls-1">
                        <img src={sd1} alt="" />
                    </div>
                    
                    <div className="cls-2">
                        Gorem ipsum
                    </div>
                    
                    <div className="cls-3">
                        Gorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </div>
                </div>
            </SwiperSlide>    
                                                                                
            <SwiperSlide className="supes-slide">
                <div className="clint-swipe">
                    <div className="cls-1">
                        <img src={sd1} alt="" />
                    </div>
                    
                    <div className="cls-2">
                        Gorem ipsum
                    </div>
                    
                    <div className="cls-3">
                        Gorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </div>
                </div>
            </SwiperSlide>    
                                                                                
            <SwiperSlide className="supes-slide">
                <div className="clint-swipe">
                    <div className="cls-1">
                        <img src={sd1} alt="" />
                    </div>
                    
                    <div className="cls-2">
                        Gorem ipsum
                    </div>
                    
                    <div className="cls-3">
                        Gorem ipsum dolor sit amet, consectetur adipiscing elit.Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    </div>
                </div>
            </SwiperSlide>    
                                                            
        </div>
        
      </Swiper>
        
      </div>
    </>
  )
}

export default ClientsSlider