import React from "react";
import { Link } from "react-router-dom";
import "./DoNotShare.css";

function DoNotShare() {
  return (
    <>
      <section Id="dont_share_wrapper">
        <div className="share_main container">
          <p className="share_head">Do not sell my personal information</p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure sit
            dignissimos officia nisi optio, repudiandae voluptatibus laudantium
            asperiores tempora, reprehenderit, quibusdam nesciunt animi!
            Voluptatibus, deserunt hic recusandae temporibus expedita qui
            consequuntur voluptates dolor sunt doloribus neque. Labore maiores
            vel consectetur mollitia doloremque quo saepe voluptates vero cumque
            a. Dolorem eos praesentium quaerat nobis sed! Dicta aut modi
            repellendus veritatis pariatur delectus culpa hic deserunt,
            temporibus enim tenetur vel adipisci facilis atque dolorum sapiente
            accusantium vero reiciendis quisquam, asperiores ab. Impedit quasi
            libero unde neque repellat corporis necessitatibus facilis labore.
            Vel ullam praesentium deserunt nesciunt minima. Ad, corporis quis ex
            voluptatem ab blanditiis consequuntur at! Aut, vero perferendis
            reiciendis nam odit nostrum similique blanditiis optio fugit alias
            iusto delectus magnam fugiat unde quis sapiente ea aliquid
            laudantium! Natus quos perferendis vel esse alias et earum enim
            minus necessitatibus voluptatem quia suscipit exercitationem
            officiis, consequatur fugiat accusamus corporis veniam vitae maiores
            blanditiis.Please see our <Link to="/">Privacy & Policy</Link>
          </p>
          <p className="share_head">More information :</p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum
            repudiandae praesentium expedita placeat facere nobis perspiciatis
            dignissimos ab totam quo non, aperiam omnis, nam maxime dolore
            accusamus consectetur error? Eum ipsam nesciunt impedit aperiam
            voluptates incidunt a fuga, possimus, laudantium quibusdam ex
            corrupti recusandae sit iusto officiis expedita ipsa numquam!. Lorem
            ipsum dolor sit amet consectetur, adipisicing elit. Dolorum
            repudiandae praesentium expedita placeat facere nobis perspiciatis
            dignissimos ab totam quo non, aperiam omnis, nam maxime dolore
            accusamus consectetur error? Eum ipsam nesciunt impedit aperiam.
          </p>
          <p className="share_head">More information :</p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum
            repudiandae praesentium expedita placeat facere nobis perspiciatis
            dignissimos ab totam quo non, aperiam omnis, nam maxime dolore
            accusamus consectetur error? Eum ipsam nesciunt impedit aperiam
            voluptates incidunt a fuga, possimus, laudantium quibusdam ex
            corrupti recusandae sit iusto officiis expedita ipsa numquam!. Lorem
            ipsum dolor sit amet consectetur, adipisicing elit. Dolorum
            repudiandae praesentium expedita placeat facere nobis perspiciatis
            dignissimos ab totam quo non, aperiam omnis, nam maxime dolore
            accusamus consectetur error? Eum ipsam nesciunt impedit aperiam.
          </p>
          <p className="share_head">More information :</p>
          <p>
            voluptates incidunt a fuga, possimus, laudantium quibusdam ex
            corrupti recusandae sit iusto officiis expedita ipsa numquam!.
            Please visit our <Link to="/privacy">Privacy & Policy</Link> for more
            information
          </p>
          <p>
            Please go back to <Link to="/">Homepage</Link>
          </p>
        </div>
      </section>
    </>
  );
}

export default DoNotShare;
