import React from "react";

import { testimonies } from "./../../constants/links";
import "./slider.css";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/pagination";

const TestimonySlider = () => {
  return (
    <Swiper
      loop={false}
      navigation={true}
      grabCursor={true}
      modules={[Pagination, Navigation]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="testimonySwiper"
    >
      {testimonies.map((links) => {
        return (
          <SwiperSlide key={links.id} className="testimonial">
            <article className="swiper-slide">
              <p>{links.text}</p>
              <div className="testifier ">
                <div className="testifier __details">
                  <h5>{links.person}</h5>
                </div>
              </div>
            </article>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default TestimonySlider;
