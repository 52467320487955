import React from "react";
import { useEffect } from "react";
// import { Link } from "react-router-dom";

// import Swiper from "swiper";
// import Swiper, { Navigation, Pagination } from "swiper";

import "./home.css";
import headerVideo from "./../../assets/videos/Plexus_Alpha_Background_1_long.mp4";
import shieldImage from "./../../assets/images/padlock.png";
import logoSpin from "./../../assets/images/Nugent3d.png";
import icon1 from "./../../assets/images/1.png";
import icon2 from "./../../assets/images/2.png";
import icon3 from "./../../assets/images/3.png";
import img1 from "./../../assets/images/Path-28.png";
import Plexus from "./../../assets/videos/Plexus_Alpha_Background_1_long_1.mp4";
import TestimonySlider from "../../components/TestimonySlider";
import ScrollToTop from "./../../components/ScrollToTop";
// AOS 
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';

AOS.init();

const HomePage = () => {

  useEffect(() => {
    AOS.init({
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });

  return (
    <>
      <section id="header-section">
        <header className="showcase">
          <div className="container">
            <div className="left"
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              <h1>
                Get empowered
                <br />
                to be safe
                <span> !</span>
              </h1>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum
                accusamus iure expedita cum aliquam et ex, ipsa magni molestias
                ad consequatur alias omnis non consequuntur voluptatem corrupti?
                Nostrum non laborum nam quod mollitia pariatur, ut temporibus
                nobis fugit magnam sequi?
              </p>
            </div>
            <div className="right"
              data-aos="zoom-in-up"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              <img src={shieldImage} alt="shield" />
            </div>
          </div>
          <video
            src={headerVideo}
            autoPlay
            muted
            playsInline
            loop
            type="video/mp4"
            id="hero-bgVid"
          />
        </header>
      </section>

      <section id="who-are-we">
        <div className="container">
          <div className="left"></div>
          <div className="right">
            <h1
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >
              Who are we
              <span> ?</span>
            </h1>
            <p
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam
              autem, natus est accusamus ullam itaque! Provident repellat
              eveniet corporis voluptatem hic optio distinctio et eaque suscipit
              ducimus maxime earum cumque facere dolor laudantium dolore, minima
              ut. At unde exercitationem rem!
            </p>
            <Link to='/aboutUs' className="btn-main"
              data-aos="zoom-in-up"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              learn more
            </Link>
          </div>
        </div>
      </section>

      <section id="mission">
        <div className="container">
          <article>
            <h1
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >Our Mission</h1>
            <p
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum
              dolores obcaecati sit. Quae perspiciatis nisi sed sunt quo? Illo
              laborum sit omnis tenetur ipsa praesentium quis incidunt nam optio
              fuga.
            </p>
          </article>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="top">
            <h1
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >Our services</h1>
            <p
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Necessitatibus similique natus esse, quas ea explicabo odit quia
              facilis quaerat, sint voluptas voluptatum neque quo nobis?
              Molestiae est, iure dolore cum nostrum illum optio maxime officia.
            </p>
          </div>
          <div className="bottom">
            <div className="service-cards">
              <div className="service-card">
                <div className="logo-spin"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-delay="600"
                  data-aos-anchor-placement="top-bottom"
                >
                  <img src={logoSpin} alt="logo" />
                </div>
              </div>
              <div className="service-card"
                data-aos="zoom-in-up"
                data-aos-easing="ease-out-cubic"
                data-aos-delay="200"
                data-aos-anchor-placement="top-bottom"
              >
                <h3>mineral fraud recoveries</h3>
                <img src={icon1} alt="mineral" />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  quasi dolore ea harum sit quidem nemo quos facere impedit
                  repudiandae!
                </p>
                <Link to="/mineral_fraud" className="llmm">learn more
                </Link>
              </div>
              <div className="service-card"
                data-aos="zoom-in-up"
                data-aos-easing="ease-out-cubic"
                data-aos-delay="400"
                data-aos-anchor-placement="top-bottom"
              >
                <h3>mineral purchases</h3>
                <img src={icon2} alt="mineral" />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  quasi dolore ea harum sit quidem nemo quos facere impedit
                  repudiandae!
                </p>
                <Link to="/mineral_purchases" className="llmm">learn more
                </Link>

              </div>
              <div className="service-card"
                data-aos="zoom-in-up"
                data-aos-easing="ease-in-cubic"
                data-aos-delay="600"
                data-aos-anchor-placement="top-bottom"
              >
                <h3>data protection service</h3>
                <img src={icon3} alt="data protection" />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  quasi dolore ea harum sit quidem nemo quos facere impedit
                  repudiandae!
                </p>

                <Link to="/data_protection" className="llmm">learn more

                </Link>

              </div>
            </div>
            <div className="backdrop" />
          </div>
        </div>
      </section>

      <section id="testimonies">
        <div className="container">
          <div className="top">
            <h1
              data-aos="zoom-in-up"
              data-aos-easing="ease-in-cubic"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >Hear it from our clients</h1>
            <p
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-anchor-placement="top-bottom"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
              reiciendis saepe consequuntur adipisci a veniam quae, sed
              blanditiis quo voluptate cupiditate nostrum rerum dolorum odit
              provident tempore eaque voluptatibus vero.
            </p>
          </div>
          <div className="bottom">
            <div className="testimony">
              <div className="quote-sign">
                <img src={img1} alt="quotation" />
              </div>
              <TestimonySlider />
            </div>
          </div>
        </div>
      </section>

      <section id="privacy">
        <video
          src={Plexus}
          autoPlay
          muted
          playsInline
          loop
          id="privacy-bgVid"
        />

        <div className="container">
          <article>
            <h1
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
            >
              "Your privacy is a right,
              <br />
              not a privilege"
            </h1>
          </article>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default HomePage;
