import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./dataProtection.css";

// images
import folderImg from "../../assets/images/folder.png";
import dataAccesImg from "../../assets/images/data_access.png";
import dataBackupImg from "../../assets/images/data-backup.png";
import cloudDataImg from "../../assets/images/cloud-mass-data.png";
import ransomeImg from "../../assets/images/ransomware.jpg";
import amazonecloudImg from "../../assets/images/amazoncloud.png";
import cloudcraftImg from "../../assets/images/cloudcraft.png";
import createlyImg from "../../assets/images/creately.png";
import drawImg from "../../assets/images/draw.png";
import ScrollToTop from "./../../components/ScrollToTop";

AOS.init();

function DataProtection() {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });
  return (
    <>
      <section id="data_protection_showcase">
        <div className="data_showcase_wrapper container">
          <div className="left">
            <div className="head">
              <div className="line"></div>
              <div className="text">
                <p
                  className="sub_head"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  Nugent secured and most reliable data protection
                </p>
                <p
                  data-aos="fade-in"
                  data-aos-delay="600"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  Unlimited protection for everyone..{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <img
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-duration="700"
              data-aos-easing="ease-in-cubic"
              data-aos-anchor-placement="top-bottom"
              src={folderImg}
              alt="folder"
            />
          </div>
        </div>
      </section>

      {/* ======= WHY:SECTION ======= */}
      <section id="data_protection_why">
        <div className="data_why_wrapper container">
          <div className="top">
            <h2
              className="sub_head"
              data-aos="zoom-in-up"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="100"
              data-aos-anchor-placement="top-bottom"
            >
              Why Nugent
            </h2>
            <p
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              The Nugent consult offers comprehensive and custom data protection
              solutions for both SMEs and large businesses. Our approach has
              made Nugent one of the most popular Data Protection service
              providers in Ghana.
            </p>
          </div>
          <div className="bottom">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
              className="why_card"
            >
              <img src={dataAccesImg} alt="" />
              <div className="text">
                <p>Easy access to data</p>
                <p>Get easy access to backed-up data</p>
              </div>
            </div>
            <div
              className="why_card"
              data-aos="zoom-in-up"
              data-aos-delay="400"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <img src={dataBackupImg} alt="" />
              <div className="text">
                <p>Standard + Integrity</p>
                <p>
                  Nugent has 99.99% backup success rate which makes us a
                  reliable partner
                </p>
              </div>
            </div>
            <div
              className="why_card"
              data-aos="zoom-in-up"
              data-aos-delay="800"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <img src={cloudDataImg} alt="" />
              <div className="text">
                <p>Secure</p>
                <p>High level data protecction security</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= RANSOMEWARE SECTION ======= */}

      <section id="data_ransomeware">
        <div className="ransomeware_wrapper container">
          <div className="left">
            <img
              data-aos="flip-left"
              data-aos-delay="200"
              data-aos-duration="600"
              data-aos-easing="ease-out-cubic"
              src={ransomeImg}
              alt=""
            />
          </div>
          <div className="right">
            <h2
              className="sub_head"
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Do not Pay Ransomeware
            </h2>
            <p
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-easing="ease-in-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos odio mattis. Class aptent taciti sociosqu ad
              litora torquent per conubia nostra, per inceptos himenaeos
            </p>
          </div>
        </div>
      </section>

      {/* ======= SECTION: TRUST ======= */}
      <section id="data_protection_trust">
        <div className="trust_wrapper container">
          <div className="top">
            <h2
              className="sub_head"
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Our Trusted Partners
            </h2>
            <p
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-easing="ease-in-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Jorem ipsum dolor sit amet, consectetu Jorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nunc vulputate libero et velit
              interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad
              litora torquent per conubia nostra, per inceptos himenaeos.
            </p>
          </div>
          <div className="bottom">
            <img src={cloudcraftImg} alt="" />
            <img src={createlyImg} alt="" />
            <img src={drawImg} alt="" />
            <img src={amazonecloudImg} alt="" />
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
}

export default DataProtection;
