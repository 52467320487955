import React from "react";
import "./AboutUs.css";
import abtlog from "../../assets/images/Frame111.png";
import viss1 from "../../assets/images/viss1.jpg";
import viss2 from "../../assets/images/viss2.jpg";
import sta from "../../assets/images/sta.png";
import expe from "../../assets/images/expe.png";
import moni from "../../assets/images/moni.png";
import { useEffect } from "react";
// import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "../../components/ScrollToTop/index";

AOS.init();

const AboutUs = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });

  return (
    <div id="about-wrapper">
      <div className="about-show">
        <div className="about-case">
          {/* showcase  */}

          <div className="about-showcase-1">
            <div className="about-showcase-2">
              <div className="about-showcase-txt">
                <div className="ast1 showcase-txt-1">ABOUT US</div>

                <div className="ast2 showcase-txt-2">
                  Protect Your Data with Our Expert Data Protection Services
                </div>
              </div>
            </div>
          </div>

          {/* showcase end */}

          {/* who we are  */}

          <div className="whowe-wrapper">
            <div className="whowe-case">
              <div className="whowe-show">
                <div className="who-we">
                  <div className="whowe-left">
                    <div className="whowe-left-case">
                      <div
                        className="who-left-pic"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="400"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <img src={abtlog} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="whowe-right">
                    <div className="whowe-right-case">

                      <div className="who-right-txt-1"
                        data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="400"
                        data-aos-anchor-placement="top-bottom"
                      >
                        Who We Are ?
                      </div>

                      <div className="who-right-txt-2 sub_head"
                        data-aos="fade-in"
                        data-aos-easing="ease-in"
                        data-aos-delay="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        The Nugent Consult remains the standpoint as a flawless
                        service provider of Mineral Fraud Recovery, Mineral
                        Purchase, and Data Privacy services in Ghana. We have
                        thoroughly trained consultants in Mineral Fraud
                        Recovery, Mineral Purchase, and Data Privacy services
                        who will give you the best of our services Our
                        consultants will give the best answer for your company
                        whether you need assistance with your end - to - end
                        compliance journey or a solution to a specific
                        difficulty – no matter how small or large the
                        organization is. With over 7 years of expertise working
                        across various organizations nationally, you can trust
                        that we deliver professional, bespoke and excellent
                        solutions that work for your organization – so you can
                        get it right the first time. The Nugent Consult strives
                        to be the partner of its clients’ choice by providing
                        value for all.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* who we are end */}

          {/* mission & vision  */}

          <div id="mv-wrapper">
            <div className="mv-case">
              <div className="mv-show container">
                <div className="mv">
                  <div className="mv-top">
                    <div className="mv-top-left">
                      <div
                        className="mv-tl-case"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="200"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="mv-tl-box"></div>
                        <img src={viss1} alt="" />
                      </div>
                    </div>

                    <div className="mv-top-right">
                      <div className="mv-tr-case">

                        <div className="mv-tr-1"
                          data-aos="fade"
                          data-aos-easing="ease-in"
                          data-aos-delay="400"
                          data-aos-anchor-placement="top-bottom"
                        >
                          OUR MISSION
                        </div>

                        <div className="mv-tr-2"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="600"
                          data-aos-anchor-placement="top-bottom"
                        >
                          To successfully provide services to organizations
                          around the nation enabling them to purchase or recover
                          minerals on schedule, in the appropriate numbers, and
                          in accordance with the right specifications by
                          utilizing our staff 's promptness, diligence,
                          experience, and dedication. Also, to enable
                          organizations in Ghana to protect the privacy of the
                          personal information of its stakeholders.
                        </div>

                        <div className="mv-tr-3"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="800"
                          data-aos-anchor-placement="top-bottom"
                        >
                          Our mission here is to protect our clients ' sensitive
                          data and personal information from all threats,
                          ensuring that their valuable assets are safe and
                          secure at all times. We are committed to providing
                          innovative and effective data protection solutions
                          that are easy to use, reliable, and scalable. We aim
                          to build lasting relationships with our clients based
                          on trust, transparency, and mutual respect, and to
                          always operate with the highest standards of
                          professionalism and ethics.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mv-bot">
                    <div className="mv-bot-left">
                      <div className="mv-tr-case">

                        <div className="mv-tr-1"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="400"
                          data-aos-anchor-placement="top-bottom"
                        >
                          OUR VISION
                        </div>

                        <div className="mv-tr-2"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="600"
                          data-aos-anchor-placement="top-bottom"
                        >
                          Our vision is to be the leading provider of data
                          protection service, mineral purchases and recovering
                          mineral frauds for organizations and individual
                          worldwide.We strive to deliver high - quality,
                          innovative, and secure data solutions that help our
                          clients to achieve their business objectives, while
                          setting the standard for excellence and ethical
                          business practices in the data industry.
                        </div>

                        <div className="mv-tr-3"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="800"
                          data-aos-anchor-placement="top-bottom"
                        >
                          Our vision is to create a world where individuals and
                          organizations can trust that their data is safe,
                          secure, and protected from all threats.We strive to be
                          the leading provider of data protection solutions that
                          enable our clients to operate with confidence, knowing
                          that their data is protected from unauthorized access,
                          cyberattacks, and other security threats.We believe
                          that everyone has the right to control their personal
                          information, and we aim to empower individuals and
                          organizations to exercise that right with our
                          innovative and trustworthy data protection solutions.
                        </div>
                      </div>
                    </div>

                    <div className="mv-bot-right">
                      <div
                        className="mv-tl-case"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="400"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="mv-tl-box"></div>
                        <img src={viss2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mission & vision end */}

          {/* why us  */}

          <div className="whyus-wrapper">
            <div className="whyus-case container">
              <div className="whyus-show">
                <div className="why-us">
                  <div
                    className="why-us-1"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="200"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Why choose us ?
                  </div>

                  <div className="why-us-2"
                    data-aos="fade-in"
                    data-aos-easing="ease-in"
                    data-aos-delay="400"
                    data-aos-anchor-placement="top-bottom"
                  >
                    We understand that every organization has unique data
                    protection needs. Therefore, we offer customized solutions
                    that are tailored to your specific requirements.
                  </div>

                  <div className="why-us-3">
                    <div className="choose">
                      <div
                        className="choose-1"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="400"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="cho1">Experience</div>
                        <div className="cho2">
                          <img src={expe} alt="" />
                        </div>
                        <div className="cho3">
                          Our team of experts has years of experience in the
                          field of data protection.We have successfully provided
                          data protection services to numerous clients and have
                          a proven track record of securing their data.
                        </div>
                      </div>

                      <div
                        className="choose-1"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="cho1">State-of-the-art Technology</div>
                        <div className="cho2">
                          <img src={sta} alt="" />
                        </div>
                        <div className="cho3">
                          We use the latest state - of -the - art technology to
                          protect your data.Our tools and systems are regularly
                          updated to ensure they stay up - to - date with the
                          latest security measures.
                        </div>
                      </div>

                      <div
                        className="choose-1"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="800"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="cho1">24/7 Monitoring and Support</div>
                        <div className="cho2">
                          <img src={moni} alt="" />
                        </div>
                        <div className="cho3">
                          Our team provides 24 / 7 monitoring and support to
                          ensure that your data is always secure.We proactively
                          monitor your data and respond quickly to any security
                          incidents or issues.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* why us end */}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default AboutUs;
