import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import {
  HomePage,
  Service,
  Mission,
  AboutUs,
  Testimonial,
  ContactUs,
  DataProtection,
  MineralFraud,
  MineralPurchases,
  Privacy,
  Trust,
  AddChoices,
  DoNotShare,
  NotFound,
} from "./pages/index";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/services" element={<Service />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/data_protection" element={<DataProtection />} />
        <Route path="/mineral_fraud" element={<MineralFraud />} />
        <Route path="/mineral_purchases" element={<MineralPurchases />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/trust" element={<Trust />} />
        <Route path="/add_choices" element={<AddChoices />} />
        <Route path="/donotshare" element={<DoNotShare />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
