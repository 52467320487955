import React from "react";
import "./Mission.css";
import strategy from "../../assets/images/strategy.png";
import support from "../../assets/images/support.png";
import results from "../../assets/images/results.png";
import expertise from "../../assets/images/expertise.png";
import cile from "../../assets/images/ciile.png";
import ScrollToTop from "./../../components/ScrollToTop";
import { useEffect } from "react";
// import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";


AOS.init();



const Mission = () => {

  useEffect(() => {
    AOS.init({
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });

  return (
    <div id="mission-wrapper">
      <div className="mission-case">
        <div className="mission-show">
          {/* showcase  */}

          <div className="mission-showcase-1">
            <div className="mission-showcase-2">
              <div className="mission-showcase-txt">
                <div className="ast1 showcase-txt-1">OUR MISSION</div>

                <div className="ast2 showcase-txt-2">
                  We Power Innovation because We Are Innovators
                </div>
              </div>
            </div>
          </div>

          {/* showcase end */}

          {/* strategy  */}

          <div id="strategy-wrapper">
            <div className="strategy-case">
              <div className="strategy-show container">
                <div className="strategy">

                  <div className="strategy-1"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="200"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="stra-1">
                      <img src={strategy} alt="" />
                    </div>
                    <div className="stra-2">STRATEGY</div>
                    <div className="stra-3">
                      Our strategy is its overall plan of action to achieve its
                      goals and objectives.It typically involves identifying the
                      company 's target market, developing a unique value
                      proposition, identifying the most effective marketing and
                      sales channels, and setting performance goals and
                      objectives.
                    </div>
                  </div>

                  <div className="strategy-1"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="400"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="stra-1">
                      <img src={results} alt="" />
                    </div>
                    <div className="stra-2">RESULTS</div>
                    <div className="stra-3">
                      These can include financial performance, market share,
                      customer satisfaction, employee engagement, and other key
                      performance indicators(KPIs).Tracking and analyzing
                      results is critical to understanding a company 's overall
                      performance and identifying areas for improvement.
                    </div>
                  </div>

                  <div className="strategy-1"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="600"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="stra-1">
                      <img src={expertise} alt="" />
                    </div>
                    <div className="stra-2">EXPERTISE</div>
                    <div className="stra-3">
                      A company can demonstrate its expertise through thought
                      leadership, industry publications, speaking engagements,
                      and other forms of content marketing.This helps to build
                      trust with customers and partners, and positions the
                      company as a leader in its field.
                    </div>
                  </div>

                  <div className="strategy-1"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="800"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="stra-1">
                      <img src={support} alt="" />
                    </div>
                    <div className="stra-2">SUPPORT</div>
                    <div className="stra-3">
                      This might include customer support, technical support,
                      employee training and development, and community
                      outreach.Providing effective support is critical to
                      building strong relationships with customers and can help
                      differentiate a company from its competitors.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* strategy end */}

          {/* together  */}

          <div className="toge-wrapper">
            <div className="toge-case">
              <div className="toge-show container">
                <div className="toge">
                  <div className="toge-1"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="200"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Together we can envision, inspire and transform your
                    business.
                  </div>

                  <div className="toge-2">

                    <div className="toge2-left"
                      data-aos="fade-in"
                      data-aos-easing="ease"
                      data-aos-delay="400"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="toge2-lt">
                        We have helped business increase their revenue by 90% in
                        their first term with us!
                      </div>

                      <div className="toge2-lb">
                        At Nugent, we are committed to providing
                        [products/services] that improve the daily lives of our
                        customers. We believe that everyone should have access
                        to [quality/innovative/etc.] solutions that make their
                        lives easier and more fulfilling.
                      </div>
                    </div>

                    <div className="toge2-right"
                      data-aos="fade-in"
                      data-aos-easing="ease"
                      data-aos-delay="600"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="toge2-rt">
                        Explore Solutions For Every Budget and Challenge
                      </div>

                      <div className="toge2-rb">
                        <div className="toge2-rb-1">
                          <div className="trb-log">
                            <img src={cile} alt="" />
                          </div>
                          <div className="trb-txt">Data Privacy</div>
                        </div>
                        <div className="toge2-rb-1">
                          <div className="trb-log">
                            <img src={cile} alt="" />
                          </div>
                          <div className="trb-txt">Security</div>
                        </div>
                        <div className="toge2-rb-1">
                          <div className="trb-log">
                            <img src={cile} alt="" />
                          </div>
                          <div className="trb-txt">Customer Satisfactory</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* together end */}

          {/* values  */}

          <div className="values-wrapper">
            <div className="values-case">
              <div className="values-show container">
                <div className="values">
                  <div className="values-1"
                    data-aos="fade-in"
                    data-aos-easing="ease"
                    data-aos-delay="400"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Our Values
                  </div>

                  <div className="values-2">
                    At Nugent, our values guide everything we do.They are the
                    foundation of our culture and inform our decisions,
                    interactions, and relationships with others.We understand
                    that the key to success is our people.That 's why we invest
                    in our employees, providing them with the training, support,
                    and resources they need to excel in their roles. Our diverse
                    and inclusive work environment fosters collaboration,
                    creativity, and growth, allowing us to bring our best to
                    every project we undertake.
                  </div>

                  <div className="values-3">

                    <div className="val3"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="400"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="val3-a">INTEGRITY</div>
                      <div className="val3-b">
                        We understand that the key to success is our people.That
                        's why we invest in our employees, providing them with
                        the training, support, and resources they need to excel
                        in their roles. Our diverse and inclusive work
                        environment fosters collaboration, creativity, and
                        growth, allowing us to bring our best to every project
                        we undertake.
                      </div>
                    </div>

                    <div className="val3"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="600"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="val3-a">EXCELLENCE</div>
                      <div className="val3-b">
                        We strive for excellence in everything we do, from
                        product design to customer service. We believe in
                        delivering our best every time.
                      </div>
                    </div>

                    <div className="val3"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="800"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="val3-a">INNOVATION</div>
                      <div className="val3-b">
                        We are driven by a passion for innovation and continuous
                        improvement.We embrace change and seek out new ideas to
                        stay ahead of the curve.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* values end */}

          <div id="mission-page">
            <div className="mission-case-page">
              <div className="mission-show-page container">
                <div className="mission-page">
                  <div className="mission-page-top"
                    data-aos="fade-in"
                    data-aos-easing="ease"
                    data-aos-delay="200"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Our Mission
                  </div>

                  <div className="mission-page-bot"
                    data-aos="fade-in"
                    data-aos-easing="ease"
                    data-aos-delay="400"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Our mission is to empower organizations with data - driven
                    insights to help them make informed decisions, optimize
                    their operations, and achieve their goals.We believe that
                    every organization, regardless of size or industry, deserves
                    access to the latest data technologies and the expertise
                    needed to make the most of their data.
                  </div>

                  <div className="mission-page-bot"
                    data-aos="fade-in"
                    data-aos-easing="ease"
                    data-aos-delay="400"
                    data-aos-anchor-placement="top-bottom"
                  >
                    We are more than just a provider of [products / services].We
                    are a team of dedicated professionals who are passionate
                    about making a difference and improving the world.We invite
                    you to join us on our mission to deliver value, exceed
                    expectations, and make a positive impact.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mission end */}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Mission;
