export const faqData = [
  {
    question: "Frequently Asked Questions?",
    answer:
      "Forem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    question: "Frequently Asked Questions?",
    answer:
      "Forem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    question: "Frequently Asked Questions?",
    answer:
      "Forem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    question: "Frequently Asked Questions?",
    answer:
      "Forem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    question: "Frequently Asked Questions?",
    answer:
      "Forem ipsum dolor sit amet, consectetur adipiscing elit. Nuncvulputate libero et velit interdum, ac aliquet odio mattis.",
  },
];
