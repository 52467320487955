import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Accordion from "../../components/accordion/Accordion";
import "./Service.css";

// IMAGES
import cloudImg from "../../assets/images/cloud.png";
import miningImg from "../../assets/images/mining-icon.png";
import scamShieldImg from "../../assets/images/scamShield.png";
import fraudImg from "../../assets/images/fraud.png";
import crystalImg from "../../assets/images/crystal.png";
import encryptedImg from "../../assets/images/encrypted.png";
import personImg from "../../assets/images/person_pin.png";
import globeImg from "../../assets/images/globe.png";
import awardImg from "../../assets/images/award_badge.png";
import ScrollToTop from "./../../components/ScrollToTop";

AOS.init();

const Service = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });
  return (
    <div>
      <section id="service_showcase">
        <div className="showcase_wrapper">
          <div className="top">
            <h2 className="sub_head">OUR SERVICES</h2>
          </div>
          <div className="bottom">
            <img src={cloudImg} alt="" />
            <img src={miningImg} alt="" />
            <img src={scamShieldImg} alt="" />
          </div>
        </div>
      </section>

      {/* ======= SERVICE_WHAT ======= */}
      <section id="service_what">
        <div className="service_what_wrapper">
          <div className="top container">
            <p
              className="sub_head"
              data-aos="zoom-in-up"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="100"
              data-aos-anchor-placement="top-bottom"
            >
              What We Do At Negent
            </p>
            <p
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Non vel
              culpa ea quae aliquam eaque quasi sapiente exercitationem
              doloribus eveniet ad ullam aspernatur maiores nulla voluptatem
              itaque, optio, nihil laborum?
            </p>
          </div>
          <div className="bottom">
            <h2 className="sub_head">Our Core</h2>
            <div className="box_content  container">
              <div className="s_box">
                <img src={fraudImg} alt="" />
                <p>Mineral Fraud Recoveries</p>
                <p>
                  We offer you the following services to answer your questions
                  and assuage your worries:
                </p>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Link to="/mineral_fraud" className="btn-main">
                    learn more
                  </Link>
                </div>
              </div>
              <div className="s_box">
                <img src={crystalImg} alt="" />
                <p>Mineral Purchase</p>
                <p>
                  We offer you the following services to answer your questions
                  and assuage your worries
                </p>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="400"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Link to="/mineral_purchases" className="btn-main">
                    learn more
                  </Link>
                </div>
              </div>
              <div className="s_box">
                <img src={encryptedImg} alt="" />
                <p>Data Protection Services</p>
                <p>
                  We offer you the following services to answer your questions
                  and assuage your worries:
                </p>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="800"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Link to="/data_protection" className="btn-main">
                    learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= SERVICE_DEALINGS SECTION ======= */}
      <section id="service_dealings">
        <div className="service_dealings_wrapper container">
          <div className="top">
            <p
              className="sub_head"
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-easing="ease-in-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              More on our dealings
            </p>
          </div>
          <Accordion title="Data Protection Advice">
            <p>
              Provide guidance and advice to your team in relation to your
              obligations to comply with relevant Privacy legislation.
            </p>
          </Accordion>
          <Accordion title="On-going process">
            <p>
              Monitoring of your compliance with data protection laws and with
              your internal data protection policies
            </p>
          </Accordion>
          <Accordion title="Risk control">
            <p>
              We take precautions by carefully reviewing and analyzing each
              choice to ensure that the dangers are as low as feasible. Our
              professionals know when to play it safe and when to go all out!
            </p>
          </Accordion>
          <Accordion title="Strategic initiatives">
            <p>
              These businesses have a lengthy history of defrauding people. They
              give you the impression that there is no way to get your money
              back. Based on our expertise, we can take measures to increase
              your chances of getting your money back.
            </p>
          </Accordion>
          <Accordion title="Point of contact">
            <p>
              Act as the first point of contact for individuals whose data is
              processed by your organization.
            </p>
          </Accordion>
          <Accordion title="Planning">
            <p>
              Our team of professionals compiles a full history of your
              experience, step by step, trade by trade, and builds an
              irrefutable case, finding all feasible paths to give the most
              success for your minerals reacquisition when the investigation is
              completed.
            </p>
          </Accordion>
          <Accordion title="Information evaluation">
            <p>
              The first step in recovering minerals from fraud is to determine
              whether or not the firm in issue is legal. Then, in order to take
              the next step, we must determine the specific irregularities that
              occurred, who is truly to blame, and what the proper pathways are.
            </p>
          </Accordion>
        </div>
      </section>

      {/* ======= SERVICE_AWARDS SECTION ======= */}
      <section id="service_awards">
        <div className="service_awards_wrapper container">
          <div
            className="type"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            <img src={awardImg} alt="" />
            <h3>Clients 1000+</h3>
          </div>
          <div
            className="type"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            <img src={globeImg} alt="" />
            <h3>Locations 6+</h3>
          </div>
          <div
            className="type"
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            <img src={personImg} alt="" />
            <h3>5 Awards</h3>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </div>
  );
};

export default Service;
