import React from "react";
import { faqData } from "./Data.jsx";
import "./faq.css";

function Faq() {
  return (
    <div>
      <div className="faq_wrapper">
        <div className="top container">
          <p>Frequently Asked Questions</p>
        </div>
        <div className="bottom">
          <div className="bottom_wrapper container">
            {faqData.map((item) => {
              return (
                <div className="box">
                  <p className="question">{item.question}</p>
                  <p className="answer">{item.answer}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
