import React from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./swipe.css";

// images
import siteImg1 from "../../assets/images/miningSite1.jpg";
import siteImg2 from "../../assets/images/miningSite2.jpg";
import siteImg3 from "../../assets/images/miningSite3.jpg";
import siteImg5 from "../../assets/images/miningSite5.jpg";

function Swipe() {
  return (
    <>
      <Swiper
        loop={false}
        navigation={true}
        grabCursor={true}
        modules={[Navigation]}
        spaceBetween={30}
        slidesPerView={3}
        className="swipe_container"
        breakpoints={{
          250: {
            slidesPerView: 1,
          },
          450: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
          <img src={siteImg1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={siteImg2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={siteImg3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={siteImg1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={siteImg5} alt="" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Swipe;
