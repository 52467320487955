import React from "react";
import "./ContactUs.css";
import ceoo from "../../assets/images/ceoo.png";
import teamm from "../../assets/images/teamm.jpg";
import mail from "../../assets/images/ mail1.png";
import call from "../../assets/images/call1.png";
import loc from "../../assets/images/loc1.png";
import { useEffect } from "react";

import ScrollToTop from "./../../components/ScrollToTop";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const ContactUs = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });

  return (
    <div id="contact-us-wrapper">
      <div className="contact-us-case">
        <div className="contact-us-show">
          {/* showcase  */}

          <div className="contact-us-showcase-1">
            <div className="contact-us-showcase-2">
              <div className="contact-us-showcase-txt">
                <div className="ast1 showcase-txt-1">Connect with Us</div>

                <div div className="ast2 showcase-txt-2 contact-showcase">
                  Learn more about our products and services.We 're here to help
                  with any questions you may have!
                </div>
              </div>
            </div>
          </div>

          {/* showcase end */}

          {/* ceo  */}

          <div className="ceo-wrapper">
            <div className="ceo-case">
              <div className="ceo-show container">
                <div className="ceo">
                  <div
                    className="ceo-left"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="200"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="cl-a">About Our CEO</div>
                    <div className="cl-b">JOHN DOE</div>
                    <div className="cl-b">
                      John Doe is a visionary leader and successful entrepreneur
                      with over 20 years of experience in the technology
                      industry.He is the CEO of XYZ Inc., where he leads the
                      company 's strategy and drives growth through innovative
                      solutions and a customer-centric approach.
                    </div>
                  </div>

                  <div
                    className="ceo-right"
                    data-aos="zoom-in-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-delay="400"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <img src={ceoo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ceo end */}

          {/* perfect team  */}

          <div className="pteam-wrapper">
            <div className="pteam-case">
              <div className="pteam-show container">
                <div className="pteam">
                  <div
                    className="pteam-top"
                    data-aos="fade-in"
                    data-aos-easing="ease-in"
                    data-aos-delay="300"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Our Perfect Team
                  </div>

                  <div className="pteam-bot">
                    <div className="p-teams">
                      <div
                        className="p-team-1"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="200"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="p-team-1a">
                          <div className="p-team-1a-pic">
                            <img src={teamm} alt="" />
                          </div>
                        </div>
                        <div className="p-team-1b">Mary Green</div>
                        <div className="p-team-1c">Creative Leader</div>
                        <div className="p-team-1d">
                          John Doe is a visionary leader and successful
                          entrepreneur with over 20 years of experience in the
                          technology industry.He is the CEO of XYZ Inc., where
                          he leads the company 's strategy and drives growth
                          through innovative solutions and a customer-centric
                          approach.
                        </div>
                      </div>

                      <div
                        className="p-team-1"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="400"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="p-team-1a">
                          <div className="p-team-1a-pic">
                            <img src={teamm} alt="" />
                          </div>
                        </div>
                        <div className="p-team-1b">Mary Green</div>
                        <div className="p-team-1c">Creative Leader</div>
                        <div className="p-team-1d">
                          John Doe is a visionary leader and successful
                          entrepreneur with over 20 years of experience in the
                          technology industry.He is the CEO of XYZ Inc., where
                          he leads the company 's strategy and drives growth
                          through innovative solutions and a customer-centric
                          approach.
                        </div>
                      </div>

                      <div
                        className="p-team-1"
                        data-aos="zoom-in-up"
                        data-aos-easing="ease-out-cubic"
                        data-aos-delay="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <div className="p-team-1a">
                          <div className="p-team-1a-pic">
                            <img src={teamm} alt="" />
                          </div>
                        </div>
                        <div className="p-team-1b">Mary Green</div>
                        <div className="p-team-1c">Creative Leader</div>
                        <div className="p-team-1d">
                          John Doe is a visionary leader and successful
                          entrepreneur with over 20 years of experience in the
                          technology industry.He is the CEO of XYZ Inc., where
                          he leads the company 's strategy and drives growth
                          through innovative solutions and a customer-centric
                          approach.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* perfect team end */}

          {/* contact  */}

          <div className="contact-wrapper">
            <div className="contact-wrapper1">
              <div className="contact-case">
                <div className="contact-show container">
                  <div className="contact">
                    <div
                      className="contact-left"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="200"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="cl1">Contact Us</div>
                      <div className="cl2">
                        Torem ipsum dolor sit amet, consectetur adipiscing
                        elit.Nunc vulputate libero et velit interdum, ac aliquet
                        odio mattis.
                      </div>

                      <div className="cl3">
                        <form className="contact-form">
                          <input
                            className="conf"
                            type="text"
                            placeholder="NAME"
                          ></input>

                          <input
                            className="conf"
                            type="mail"
                            placeholder="EMAIL"
                          ></input>

                          <input
                            className="conf"
                            type="text"
                            placeholder="MESSAGE"
                          ></input>

                          <div className="tm-btn">
                            <button className="btn-main">SEND</button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div
                      className="contact-right"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="400"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <div className="con-right-case">
                        {/* <div className='conr-top'>
                          <div className='conr-top-rec'>
                            <img src={rect} alt="" />
                          </div>
                        </div> */}

                        <div className="conr-bot">
                          <div className="con-b1">
                            <div className="con-b1-a">
                              <img src={loc} alt="" />
                            </div>
                            <div className="con-b1-b">
                              Jorem ipsum dolor sit amet
                            </div>
                          </div>

                          <div className="con-b1">
                            <div className="con-b1-a">
                              <img src={mail} alt="" />
                            </div>
                            <div className="con-b1-b">
                              hello @Joremipsum.com
                            </div>
                          </div>

                          <div className="con-b1">
                            <div className="con-b1-a">
                              <img src={call} alt="" />
                            </div>
                            <div className="con-b1-b">+335615862505</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* contact end */}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default ContactUs;
