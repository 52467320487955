import React from 'react'
import './Trust.css'


const Trust = () => {
  return (
    <div className='privacy-wrapper'>
        <div className='privacy-case'>
            <div className='privacy-show container'>
                
                <div className='privacy'>
                    
                    <div className='pri-a'>
                        <h1>
                        Nugent Trust Page
                        </h1>
                    </div>
                    
                    <div className='pri'>
                        
                        <div className='pri-b'>
                            Welcome to our trust page.At our data security company, we take trust very seriously.We understand that the confidentiality, integrity, and availability of your data are critical to your business.That 's why we have implemented several measures to ensure that your data is secure with us.
                        </div>
                        <div className='pri-b'>
                            Here are some of the ways we demonstrate our commitment to trust:
                        </div>
                        
                        <div className='pri-c'>
                            <h2>
                                Security Certifications:
                            </h2>
                        </div>
                        
                        <div className='pri-d'>
                            Our company has obtained various security certifications such as ISO 27001 and SOC 2. These certifications demonstrate our commitment to information security and prove that we have implemented industry - standard security controls.
                        </div>
                         
                        <div className='pri-c'>
                            <h2>
                                Data Encryption
                            </h2>
                        </div>
                        
                        <div className='pri-d'>
                            We use encryption to protect your data both in transit and at rest.This ensures that even
                            if an unauthorized party gains access to your data, they will not be able to read or understand it.
                        </div>
                         
                        <div className='pri-c'>
                            <h2>
                                Data Access Controls
                            </h2>
                        </div>
                        
                        <div className='pri-d'>
                            We implement strict access controls to ensure that only authorized personnel can access your data.We use multi - factor authentication, role - based access controls, and least privilege principles to limit access to data.
                        </div>
                         
                        <div className='pri-c'>
                            <h2>
                                Incident Response Plan:
                            </h2>
                        </div>
                        
                        <div className='pri-d'>
                            We have a well - defined incident response plan that outlines how we will respond to security incidents.Our team is trained to respond quickly and efficiently to any incidents that may occur.
                        </div>
                        
                        <div className='pri-c'>
                            <h2>
                                Regular Audits and Testing:
                            </h2>
                        </div>
                        
                        <div className='pri-d'>
                            We conduct regular security audits and penetration testing to identify any vulnerabilities in our systems.This allows us to proactively address any potential security issues before they become a problem.
                        </div>
                        
                        <div className='pri-d'>
                            We take pride in our commitment to security and trust, and we are always working to improve our security posture.If you have any questions or concerns about our security practices, please do not hesitate to contact us.We are always happy to discuss our security measures with our clients.
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Trust