import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./mineralFraud.css";
import mineralImg from "../../assets/images/minerals.jpg";
import Swipe from "../../components/swipper/Swipe";
import ScrollToTop from "./../../components/ScrollToTop";

AOS.init();

function MineralFraud() {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });
  return (
    <>
      <section id="fraud_showcase">
        <div className="fraud_showcase_wrapper container">
          <div className="left">
            <div className="head">
              <div className="line"></div>
              <div className="text">
                <p
                  className="sub_head"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  Mineral Fraud Recoveries
                </p>
                <p
                  data-aos="fade-in"
                  data-aos-delay="600"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  Porem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <img
              className="sub_head"
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-duration="700"
              data-aos-easing="ease-in-cubic"
              data-aos-anchor-placement="top-bottom"
              src={mineralImg}
              alt="folder"
            />
          </div>
        </div>
      </section>
      <section id="fraud_process">
        <div className="fraud_process_wrapper container">
          <div className="top">
            <h2
              className="sub_head"
              data-aos="zoom-in-up"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="100"
              data-aos-anchor-placement="top-bottom"
            >
              Our Process
            </h2>
            <p
              data-aos="zoom-in-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </p>
          </div>
          <div className="bottom">
            <div
              className="process_box"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="circle">1</div>
              <p>Legal action</p>
              <p
                data-aos="fade-in"
                data-aos-delay="200"
                data-aos-easing="ease-in-cubic"
                data-aos-anchor-placement="top-bottom"
              >
                Companies can take legal action against the fraudsters to
                recover their losses. This may involve filing a lawsuit, going
                to arbitration, or settling out of court.
              </p>
            </div>{" "}
            <div
              className="process_box"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="circle">2</div>
              <p>Insurance claims</p>
              <p
                data-aos="fade-in"
                data-aos-delay="400"
                data-aos-easing="ease-in-cubic"
                data-aos-anchor-placement="top-bottom"
              >
                Companies can take legal action against the fraudsters to
                recover their losses. This may involve filing a lawsuit, going
                to arbitration, or settling out of court.
              </p>
            </div>
            <div
              className="process_box"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="circle">3</div>
              <p>Recovery audits:</p>
              <p
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-easing="ease-in-cubic"
                data-aos-anchor-placement="top-bottom"
              >
                Companies can take legal action against the fraudsters to
                recover their losses. This may involve filing a lawsuit, going
                to arbitration, or settling out of court.
              </p>
            </div>
            <div
              className="process_box"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="circle">4</div>
              <p>Enhanced internal controls</p>
              <p
                data-aos="fade-in"
                data-aos-delay="200"
                data-aos-easing="ease-in-cubic"
                data-aos-anchor-placement="top-bottom"
              >
                Companies can take legal action against the fraudsters to
                recover their losses. This may involve filing a lawsuit, going
                to arbitration, or settling out of court.
              </p>
            </div>
            <div
              className="process_box"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="circle">5</div>
              <p>Asset tracing and recovery</p>
              <p
                data-aos="fade-in"
                data-aos-delay="400"
                data-aos-easing="ease-in-cubic"
                data-aos-anchor-placement="top-bottom"
              >
                Companies can take legal action against the fraudsters to
                recover their losses. This may involve filing a lawsuit, going
                to arbitration, or settling out of court.
              </p>
            </div>
            <div
              className="process_box"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="circle">6</div>
              <p>Restitution agreements</p>
              <p
                data-aos="fade-in"
                data-aos-delay="800"
                data-aos-easing="ease-in-cubic"
                data-aos-anchor-placement="top-bottom"
              >
                Companies can take legal action against the fraudsters to
                recover their losses. This may involve filing a lawsuit, going
                to arbitration, or settling out of court.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ======= PROCESS_EQUIPED SECTION ======= */}
      <section id="fraud_equiped">
        <div className="equiped_wrapper container">
          <div className="top">
            <h2 className="sub_head">We Are Equipped </h2>
            <p>
              recovering from mineral fraud can be a complex and time-consuming
              process, and companies may require the assistance of legal and
              financial experts to navigate the process. However, taking
              proactive steps to prevent fraud and implementing effective
              recovery measures can help companies mitigate their losses and
              protect their bottom line.
            </p>
            <p>
              "Nugent and its clients are not just a business but a family..."
            </p>
          </div>
          <div className="bottom">
            <div
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <Link to="/contactUs" className="btn-main">
                Contact Us
              </Link>
            </div>
            <p>
              "Mining provides the resources that make modern civilization
              possible, while also creating jobs and supporting local
              economies." - Ross Beaty
            </p>
          </div>
        </div>
      </section>

      {/* ======= VIEW SECTION ======= */}
      <section id="fraud_view">
        <div className="view_wrapper">
          <Swipe />
        </div>
      </section>
      <ScrollToTop />
    </>
  );
}

export default MineralFraud;
