import React from "react";
import "./Testimonial.css";
import frame12 from "../../assets/images/Frame12.png";
import Plexus from "./../../assets/videos/Plexus_Alpha_Background_1_long_1.mp4";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import fraudImg from "../../assets/images/fraud.png";
import crystalImg from "../../assets/images/crystal.png";
import encryptedImg from "../../assets/images/encrypted.png";
import ScrollToTop from "../../components/ScrollToTop";
import ClientsSlider from "../../components/ClientsSlider/ClientsSlider";

AOS.init();

const Testimonial = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });

  return (
    <div className="testimonial-wrapper">
      <div className="testimonial-case">
        <div className="testimonial-show">
          {/* showcase  */}

          <div className="testimonial-showcase-1">
            <div className="contact-us-showcase-2">
              <div className="contact-us-showcase-txt">
                <div className="ast1 showcase-txt-1">TESTIMONIALS</div>

                <div div className="ast2 showcase-txt-2 contact-showcase">
                  We are always here to help. Whether you have a question about
                  one of our products, need assistance with an order, or simply
                  want to provide feedback, we are here for you.
                </div>
              </div>
            </div>
          </div>

          {/* showcase end */}

          {/* clients  */}

          <div className="clients-wrapper">
            <div className="clients-case">
              <div className="clients-show container">
                <div className="clients">
                  <div className="clients-top">
                    <div
                      className="clients-say"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="200"
                      data-aos-anchor-placement="top-bottom"
                    >
                      What Do Our Clients Say
                    </div>
                  </div>

                  {/* clients slide  */}

                  <div className="clients-bot">{/* <ClientsSlider /> */}</div>

                  {/* clients slide end */}
                </div>
              </div>
            </div>
          </div>

          {/* clients end */}

          <ClientsSlider />

          {/* services  */}

          <div className="serve-wrapper">
            <div className="serve-case">
              <div className="serve-show">
                <div className="serve">
                  <div className="serve-left">
                    <div
                      className="sl-pic"
                      data-aos="zoom-in-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-delay="400"
                      data-aos-anchor-placement="top-bottom"
                    >
                      <img src={frame12} alt="" />
                    </div>
                  </div>

                  <div className="serve-right">
                    <div className="sr-case">
                      <div className="sr-1">
                        <div
                          className="sr-1-a"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="400"
                          data-aos-anchor-placement="top-bottom"
                        >
                          Mineral Fraud Recoveries
                        </div>
                        <div className="sr-1-b">
                          <img src={fraudImg} alt="" />
                        </div>
                        <div className="sr-1-c">
                          We offer you the following services to answer your
                          questions assuage your worries
                        </div>
                        <div className="sr-1-d">
                          <Link
                            to="/mineral_fraud"
                            className="btn-main"
                            data-aos="zoom-in"
                            data-aos-delay="200"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-cubic"
                            data-aos-anchor-placement="top-bottom"
                          >
                            learn more
                          </Link>
                        </div>
                      </div>

                      <div className="sr-1">
                        <div
                          className="sr-1-a"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="400"
                          data-aos-anchor-placement="top-bottom"
                        >
                          Mineral <br></br> Purchases
                        </div>
                        <div className="sr-1-b">
                          <img src={crystalImg} alt="" />
                        </div>
                        <div className="sr-1-c">
                          We offer you the following services to answer your
                          questions assuage your worries
                        </div>
                        <div className="sr-1-d">
                          <Link
                            to="/mineral_purchases"
                            className="btn-main"
                            data-aos="zoom-in"
                            data-aos-delay="600"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-cubic"
                            data-aos-anchor-placement="top-bottom"
                          >
                            learn more
                          </Link>
                        </div>
                      </div>

                      <div className="sr-1">
                        <div
                          className="sr-1-a"
                          data-aos="fade-in"
                          data-aos-easing="ease-in"
                          data-aos-delay="800"
                          data-aos-anchor-placement="top-bottom"
                        >
                          Data Protection Services
                        </div>
                        <div className="sr-1-b">
                          <img src={encryptedImg} alt="" />
                        </div>
                        <div className="sr-1-c">
                          We offer you the following services to answer your
                          questions assuage your worries
                        </div>
                        <div className="sr-1-d">
                          <Link
                            to="/data_protection"
                            className="btn-main"
                            data-aos="zoom-in"
                            data-aos-delay="200"
                            data-aos-duration="600"
                            data-aos-easing="ease-in-cubic"
                            data-aos-anchor-placement="top-bottom"
                          >
                            learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* services end */}

          {/* privacy  */}

          {/* <div className="privacy-wrapper">
            <div className="privacy-case">
              <div className="privacy-show">
                <div className="privacy">

                  

                </div>
              </div>
            </div>
          </div> */}


            <section id="privacy">
              <video
              src={Plexus}
              autoPlay
              muted
              playsInline
              loop
              id="privacy-bgVid"
              />

              <div className="container">
              <article>
              <h1
              data-aos="fade-in"
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-anchor-placement="top-bottom"
              >
              "Your privacy is a right,
              <br />
              not a privilege"
              </h1>
              </article>
              </div>
            </section>

          {/* privacy end */}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Testimonial;
