import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Faq from "../../components/faq/Faq";
import "./mineralPurchases.css";

// images
import helmentImg from "../../assets/images/helment.png";
import briefcaseImg from "../../assets/images/briefcase.png";
import badgeImg from "../../assets/images/badge.png";
import idCardImg from "../../assets/images/idCard.png";
import riskImg from "../../assets/images/riskControl.jpg";
import ScrollToTop from "./../../components/ScrollToTop";

function MineralPurchases() {
  useEffect(() => {
    AOS.init(
      {
        duration: 500,
        easing: "ease",
        once: false,
      },
      []
    );
  });
  return (
    <>
      <section id="mineral_showcase">
        <div className="mineral_showcase_wrapper container">
          <div className="left">
            <div className="head">
              <div className="line"></div>
              <div className="text">
                <p
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  Nugent Mineral Purchases
                </p>
                <p
                  data-aos="fade-in"
                  data-aos-delay="600"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  data-aos-anchor-placement="top-bottom"
                >
                  Porem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <span className="number">+233 52545 545</span>
          </div>
        </div>
      </section>

      {/* ======= LEARN SECTION ======= */}
      <section id="mineral_learn">
        <div className="mineral_learn_wrapper container">
          <div className="left">
            <p
              className="sub_head"
              data-aos="zoom-in-up"
              data-aos-delay="100"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Learn About Nugent Mineral Purchases
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              We receive offers to sell and buy minerals. Our clients benefit
              from us because we are secured, efficient, and can be trusted.
              Some typical issues that we can assist your company with include:
            </p>
          </div>
          <div className="right">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jYJM4TgNqNQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="utube_frame"
            ></iframe>
          </div>
        </div>
      </section>

      {/* ======= BELT SECTION ======= */}
      <section id="mineral_belt">
        <div className="belt_wrapper">
          <div
            className="belt_box"
            data-aos="zoom-in-up"
            data-aos-delay="200"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="circle">
              <img src={briefcaseImg} alt="" />
            </div>
            <p>What We Do</p>
            <p>
              We receive offers to sell and buy minerals. Our clients benefit
              from us because we are secured, efficient, and can be trusted.
            </p>
          </div>
          <div
            className="belt_box"
            data-aos="zoom-in-up"
            data-aos-delay="400"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="circle">
              <img src={helmentImg} alt="" />
            </div>
            <p>Professionalism</p>
            <p>
              The Nugent Consult remains the standpoint as a flawless service
              provider of Mineral Fraud Recovery, Mineral Purchase, and Data
              Privacy services in Ghana.
            </p>
          </div>
          <div
            data-aos="zoom-in-up"
            data-aos-delay="800"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
            className="delay_issue"
          >
            <div className="belt_box">
              <div className="circle">
                <img src={badgeImg} alt="" />
              </div>
              <p>Market Experience</p>
              <p>
                With over 7 years of expertise working across various
                organizations nationally, you can trust that we deliver
                professional, bespoke, unrivaled solutions that work for your
                organization – so you can get it right the first time.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*======= RISK_CONTROL SECTION =======*/}

      <section id="mineral_risk_control">
        <div className="risk_control_wrapper container">
          <div className="left">
            <img
              data-aos="flip-left"
              data-aos-delay="200"
              data-aos-duration="600"
              data-aos-easing="ease-out-cubic"
              src={riskImg}
              alt=""
            />
          </div>
          <div className="right">
            <div
              className="circle"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              <img src={idCardImg} alt="" />
            </div>
            <p
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              Risk Control
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-easing="ease-out-cubic"
              data-aos-anchor-placement="top-bottom"
            >
              We take precautions by carefully reviewing and analyzing each
              choice to ensure that the dangers are as low as feasible. Our
              professionals know when to play it safe and when to go all out!
            </p>
          </div>
        </div>
      </section>

      {/* ======= MINERAL_FAQ SECTION ======= */}

      <section id="mineral_faq">
        <Faq />
      </section>

      {/* ======= MINERAL_DISCLAIMER SECTION ======= */}
      <section id="mineral_disclaimer">
        <div className="mineral_disclaimer_wrapper container">
          <p
            className="sub_head"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            Diaclaimer:
          </p>
          <p
            className="disclaimer"
            data-aos="fade-in"
            data-aos-delay="400"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          >
            The information provided by our company is for general informational
            purposes only and does not constitute legal advice. We are not
            attorneys and do not provide legal services. Any information
            provided should not be construed as legal advice, and you should
            consult with an attorney for any legal advice specific to your
            situation.
          </p>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
}

export default MineralPurchases;
