import React from "react";
import { Link } from "react-router-dom";
import { navigationLinks, ourServiceLinks } from "./../../constants/links";
import { FaSistrix } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="top-footer-wrapper">
            <div className="comp-profile">
              <div className="header">
                <h2>call to action</h2>
              </div>
              <div className="profile-summary">
                <article>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Incidunt dignissimos, soluta illo deleniti quod perspiciatis
                  at saepe ex aperiam dicta!
                </article>
              </div>
              <div className="contacts">
                <ul>
                  <li>Address</li>
                  <li>Phone</li>
                  <li>Email</li>
                </ul>
              </div>
            </div>
            <div className="permalinks">
              <div className="header">
                <h2>our services</h2>
              </div>
              <div className="menu">
                {ourServiceLinks.map((links) => {
                  return (
                    <Link
                      className="service_menu_item"
                      key={links.id}
                      to={links.url}
                    >
                      {links.name}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="search-col">
              <div className="search-bar">
                <form action="">
                  <input type="text" name="search" />
                  <a className="searchButton" type="submit">
                    {/* <i className="fa fa-search"></i> */}
                    <FaSistrix className="searchIcon" />
                  </a>
                </form>
              </div>
              <div className="text-summary">
                <article>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Molestias, nulla amet vel odit quas minus ratione eveniet
                  excepturi expedita a?
                </article>
              </div>
            </div>
          </div>
          <div className="footer-menu">
            <div className="copyright">
              <div className="copy-name">
                &copy;
                <script>document.write(new Date().getFullYear());</script>
                <a
                  href="http://www.veltetech.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Velte Technology Solutions
                </a>
              </div>
              <div className="top">
                <ul>
                  <li>
                    <a href="/trust" target="_blank" rel="noopener noreferrer">
                      Trust
                    </a>
                  </li>
                  <li>
                    <a
                      href="/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/add_choices" target="_blank">
                      Ad Choices
                    </a>
                  </li>
                </ul>
              </div>
              <div className="bottom">
                <ul>
                  <li>
                    <a href="/donotshare" target="_blank">
                      Do not sell my personal information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menu-items">
              {navigationLinks.map((links) => {
                return (
                  <Link className="menu_item" key={links.id} to={links.url}>
                    {links.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
